import React from 'react'

import Layout from '../components/layout'

const BlogPage = () => (
  <Layout>
    <h1
      style={{
        fontFamily: 'georgia',
        color: 'pink',
        fontSize: '2rem',
      }}
    >
      Yeah right, I'm a cat.
    </h1>
  </Layout>
)

export default BlogPage
